//@flow

import React from 'react';

import SEO from '../components/seo';
import LoginScreen from '../screens/Login';

const RegisterPage = () => (
  <>
    <SEO title="Register" />
    <LoginScreen
      register
    />
  </>
);

export default RegisterPage;
